import React, { Component } from 'react';
import axios from "axios";
import { Segment } from 'semantic-ui-react'

export default class GetSystems extends Component{

    constructor(props){
        super(props)
        this.state = {
            query: []

        }
        
    }

    

    componentDidMount() {
        ///users/ part is from new local copy - omit if using old version hosted 10/4
        axios.get(this.props.url+'users/'+this.props.user+'/systems', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
          .then(res => {
            console.log(res);
            const query = res.data;
            this.setState({query});
          });
    }

    render(){
        return (
        <div className="comptext">
            <h3>Systems for user {this.props.user} </h3>

            <div className="ui raised segments">
                {this.state.query.map(entry => <div className="ui segment">
                    <p>{entry.thingName}</p>
                </div>)}
            </div>

            {/*{this.state.query.thingName}*/}
            {/*{*/}
            {/*this.state.query.map(entry => <Segment>{entry.thingName}</Segment>)*/}
            {/*}*/}


        </div>
        )
    }
}