import React, { Component } from 'react';
import axios from "axios";

export default class GetFeatures extends Component{

    constructor(props){
        super(props)
        this.state = {
            query: []
        }
    }

    componentDidMount() {
        axios.get(this.props.url+'features', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
          .then(res => {
            console.log(res);
            const query = res.data;
            console.log(query);
            this.setState({query});
          });
    }

    render(){
        return (
        <div className="comptext">
            <h3>List of Features available for Subscriptions</h3>
            {console.log(this.state.query)}
            {console.log(2)}
            {console.log(this.state.query.Items)}
            {
            this.state.query.map(entry => <li>{entry.featureName} {entry.cost}</li>)
            }
        </div>
        )
    }
}