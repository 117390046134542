import React, {Component} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Label, Input, Dropdown, Icon, Image, Grid, Card, Divider, Checkbox, List} from 'semantic-ui-react'



export default class FeatureStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      open: false,
      selectedFeatures: [],
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    // axios.get(this.props.url+'features', {
    //   headers: {
    //     'Authorization': this.props.bearerToken
    //   }})
    //     .then(res => {
    //       console.log(res);
    //       const query = res.data;
    //       if(Array.isArray(query)){
    //         const features = [];
    //         query.map(entry => features.push({ key: entry.featureId, text: entry.featureName + " cost: "+entry.cost, value: entry.featureId }));
    //         this.setState({features});
    //         console.log("FEATURES:");
    //         console.log(query);
    //         console.log(features);
    //       }
    //
    //     });
  }

  toggleModal(){
    if(!this.state.open){
      this.setState({open:true})
    }
    else {
      this.setState({open:false})
    }
  }

  handleFeatureSelection(feature) {
    console.log(this.state.selectedFeatures);
    if (!this.state.selectedFeatures.includes(feature)) {
      this.state.selectedFeatures.push(feature)
    } else {
      delete this.state.selectedFeatures[this.state.selectedFeatures.indexOf(feature)];
    }
  }

  render() {

    return(
        <div>

          <Modal
              closeIcon
              closeOnEscape={true}
              closeOnDimmerClick={true}
              size='large'
              trigger={
                <Button floated='right' onClick={() => this.toggleModal(1)}>
                  Store!
                </Button>
              }
              dimmer='blurring'
              open={this.state.open}
              onClose={() => this.toggleModal(1)}>

            <Modal.Header>Shop Available Features</Modal.Header>
            <Modal.Content>
              <Grid doubling columns={3}>
                {this.props.features.map((feature) => (
                    <Grid.Column>
                      <Card>
                        <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                        <Card.Content>
                          <Card.Header>{feature.key}</Card.Header>
                          <Card.Meta>
                            ${feature.cost} /month
                          </Card.Meta>
                          <Card.Description>
                            <List bulleted>
                              <List.Item>Keep an eye on your properties</List.Item>
                              <List.Item>Replace batteries before they die</List.Item>
                              <List.Item>Operate efficiently</List.Item>
                            </List>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                          <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection(feature)}/>
                          <Divider horizontal>Or</Divider>
                          <Button compact icon labelPosition='right'>
                            Subscribe Now
                            <Icon name='shop' />
                          </Button>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                ))}
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card>
                    <Image src='https://react.semantic-ui.com/images/avatar/large/matthew.png' wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>test</Card.Header>
                      <Card.Meta>
                        $2.99 /month
                      </Card.Meta>
                      <Card.Description>
                        <List bulleted>
                          <List.Item>Keep an eye on your properties</List.Item>
                          <List.Item>Replace batteries before they die</List.Item>
                          <List.Item>Operate efficiently</List.Item>
                        </List>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra textAlign='center' style={{paddingBottom: 15}}>
                      <Checkbox toggle label='Select, Continue Browsing' style={{paddingTop: 4}} onChange={this.handleFeatureSelection('test')}/>
                      <Divider horizontal>Or</Divider>
                      <Button compact icon labelPosition='right'>
                        Subscribe Now
                        <Icon name='shop' />
                      </Button>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>

              <Button negative onClick={() => this.toggleModal()} labelPosition='right'>
                Exit
              </Button>
              <Button icon positive labelPosition='right' onClick={() => this.toggleModal()}>
                Check Out With Selected Features
                <Icon name='shop' />
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
    )
  }
}
