//feeds into index.js

import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import {Tab, Container, Image, Header, Grid, Divider, Button, Menu, Label} from 'semantic-ui-react'

//import axios from "axios";

//other js files used in this app
//#FIXME delete and tidy up test js files not imported here
import GetSystems from './components/GetSystems'
import GetSystemSubscriptions from './components/GetSystemSubscriptions'
import LogOut from './components/LogOut'
import GetSubscriptions from './components/GetSubscriptions';
import AddNewSubscription from './components/AddNewSubscription';
import GetFeatures from './components/GetFeatures';
import AddFeatureToSubscription from './components/AddFeatureToSubscription'
import DeleteSubscription from './components/DeleteSubscription'
import Billing from './components/Billing'
import logo_black from './assets/lutron_black.png'
import logo_blue_circle from './assets/lutron_blue_circle.png'
import login_splash from './assets/lutron_blue_circle.png'
import {Link} from "react-router-dom";

import ManageSubscriptions from './components/ManageSubscriptions';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userid: "ejs320",
      showPage: false,
      bearerToken: "",
      //url: 'https://0ggwdnkym8.execute-api.us-east-1.amazonaws.com/api/users/'
      url: 'http://127.0.0.1:8000/',
      loggedIn: false,
      activeSubscriptionIdx: 0,
      activeMenuID: null
    };

    //for entering user for all the components
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if(this.getCookie("sessionId")){
      this.setState({loggedIn:true});
      this.setState({bearerToken: 'Bearer ' + this.getCookie("sessionId")});
      //janky login for demo
      if(this.getCookie("email")){
        var email =  this.getCookie("email");
        var id = email.slice(0,6);
        this.setState({userid: id});
      }
    }

  }

  handleChange(event){
    //the value that the user will see
    this.setState({userid: event.target.value});
  }
  handleSubmit(event){
    //only when you press enter will the value the children use will change
    this.setState({showPage: true});
    event.preventDefault();
  }

  //for reloading page when a child deletes or adds something
  selfReload = (tabIdx) => {
    console.log("received signal from child")
    console.log(tabIdx);
    this.setState({activeSubscriptionIdx: tabIdx});
    this.forceUpdate();
  };

  getCookie(name) {
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
    return match ? match[1] : null;
  }

  changeBackground(e) {
    e.target.style.background = 'red';
  }

  setActiveMenu(id) {
    this.setState({
      activeMenuID: id
    });
  }




  render () {
    // set up tabs for menu buttons




    //
    const panes = [
      {
        menuItem: {key: 'subscriptions', className: "highlightOver", icon: 'cogs', content: 'Manage Subscriptions'},
        render: () =>
            <div>
              {/*<AddNewSubscription user = {this.state.userid} url={this.state.url} bearerToken={this.state.bearerToken}/>*/}
              <br/>
              <ManageSubscriptions user = {this.state.userid} parentReload = {this.selfReload} url={this.state.url} bearerToken={this.state.bearerToken} activeTabIdx={this.state.activeSubscriptionIdx}/>
              {/*TODO: couple add subscription and add system process*/}
              {/*<AddNewSubscription user = {this.state.userid} url={this.state.url} bearerToken={this.state.bearerToken}/>*/}
            </div>
      },
      {
        menuItem: {key: 'billing', className: "highlightOver", icon: 'money bill alternate outline', content: 'Billing and Usage'},
        render: () =>

            <Billing user={this.state.userid} url={this.state.url} bearerToken={this.state.bearerToken}/>

      },
      {
        menuItem: {key: 'systems', className: "highlightOver", icon: 'microchip', content: 'Systems'},
        render: () =>
            <Tab.Pane attached={false}>
              <GetSystems user={this.state.userid} url={this.state.url} bearerToken={this.state.bearerToken}/>
              <GetSystemSubscriptions url={this.state.url} bearerToken={this.state.bearerToken}/>
            </Tab.Pane>
      }
    ];
    const MenuTabs = () => <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={{ marginTop: 0 }} />;
    return (
      <div className="App">
        <header className="App-header">
          <Container>
            <Grid stackable>
                <Grid.Column width={3}>
                  <Image src={logo_black} size='small' className='App-logo-static'/>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Header as='h2' textAlign='center' style={{ marginTop: 2, color: 'white'}}>Subscription Management Portal</Header>
                </Grid.Column>
                <Grid.Column width={3}>
                  {this.state.loggedIn && (
                      <LogOut/>
                  )}
                </Grid.Column>
            </Grid>
          </Container>
        </header>


        {this.state.loggedIn ?
           <Container>
                <MenuTabs/>
           </Container>
          :


            <Grid textAlign='center' className='login-wrapper' verticalAlign='middle'>
              <Grid.Column className='login-card' style={{maxWidth: 450, paddingBlockEnd: '5vh'}}>
                <Header as='h2' textAlign='center' style={{padding: '20px'}}>
                  <Image src={logo_blue_circle} fluid style={{margin: '24px'}}/>
                  <br/>
                  Welcome to the Subscription Management Portal!
                </Header>
                <a href='https://lutrondevelopment.herokuapp.com/oauth/authorize?client_id=ef2f4f9b0934462eb33703148c29148d331ec25137f9a1c78ee2cfc46f0d8a26&redirect_uri=http://localhost:8000/oauth/redirect&response_type=code&scope=public'>
                  <Button primary icon='sign in' content='Sign In with myLutron' labelPosition='left' size='large' />
                </a>
                <Divider horizontal className='sign-in-divider'>Or</Divider>
                <Button icon='user outline' content='Learn about Signing Up' labelPosition='right' size='medium'/>
              </Grid.Column>
            </Grid>

         }

    </div>
    )
  }
}




export default App;
