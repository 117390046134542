import React, { Component } from 'react';
import axios from "axios";
import {CanvasJSChart} from 'canvasjs-react-charts'
import {List, Segment, Card, Image, Item, Button, Grid} from 'semantic-ui-react';

export default class Billing extends Component{

    constructor(props){
        super(props);
        this.state = {
            query: [],
            subs: [],
            features: [],
            selectedFeatures: [],
            usedFeatures: {}
        };
        //loads systems for the user
        //this.GetSearch = this.GetSearch.bind(this);
        this.deleteSub = this.deleteSub.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteSubs = this.deleteSubs.bind(this);
        this.dropChange = this.dropChange.bind(this);
        this.addFeatureToSubs = this.addFeatureToSubs.bind(this);
        this.addFeatureToSub = this.addFeatureToSub.bind(this);
        this.addFeatureTally = this.addFeatureTally.bind(this);
    }


    addFeatureTally(featureName, cost){
        //if new subscription, add to list

        //this is all about adding checked items into a list to then later evaluate
        //var a = this.state.usedFeatures[featureName]
        console.log("XXXXXXXXXXXXXXXXXXXXXX")
        console.log(featureName)
        var a = this.state.usedFeatures[featureName]
        console.log("search result from searching for featureName: ")
        console.log(a)
        console.log("current state of usedFeatures:")
        console.log(this.state.usedFeatures)
        //console.log(this.state.usedFeatures['battery-check'])
        if (typeof a == 'undefined' ){
            console.log("couldn't find featurename in it")
            //add new entry for the feature
            this.setState(state=>{
                const usedFeatures = this.state.usedFeatures
                usedFeatures[featureName] = [1,cost]
                return {
                    usedFeatures,

                };
            });

        }
        else{
            var tally = this.state.usedFeatures[featureName][0]
            var cost = this.state.usedFeatures[featureName][1]
            tally = tally + 1
            console.log("tally = ")
            console.log(tally)
            this.setState(state=>{
                const usedFeatures = state.usedFeatures
                usedFeatures[featureName] = [tally, cost]
                return {
                    usedFeatures,

                };
            });
        }
        console.log("after updated state of usedFeatures:")
        console.log(this.state.usedFeatures)
        console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ")

    }


    deleteSub(subname){
        axios.delete(this.props.url+'users/'+this.props.user+'/subscriptions/'+subname, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
            .then(res => {
            console.log(res);
            const query = res.data;
            if(Array.isArray(query)){
                this.setState({query});
            }
            //this.setState({success:true});

            })
            .catch(this.setState({success:false}));
        }

    componentDidMount() {
        axios.get(this.props.url+'users/'+this.props.user+'/subscriptions', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
            .then(res => {
            console.log(res);
            const query = res.data;

            console.log(query);
            //this sets the query list with all the subscriptions
            //each entry has system,subscription id, and feature
                //feature maps to a list of features for that subscription
            this.setState({query});
            //takes the query and populates diagnostics
            console.log("this should be a fresh usedFeatures:")
            console.log(this.state.usedFeatures)
            if(Array.isArray(query)){
            this.state.query.map(entry =>
                        entry.features.map(line =>
                            this.addFeatureTally(line.featureName,line.cost)
                        )
                )
            }
          });

          axios.get(this.props.url+'features', {

            headers: {
                'Authorization': this.props.bearerToken
            }})
          .then(res => {
            console.log(res);

            const query = res.data;
            if(Array.isArray(query)){
                const features = [];
                //fills the stateful features list with all the possible attainable features
                query.map(entry => features.push({ key: entry.featureId, text: entry.featureName + " cost: "+entry.cost, value: entry.featureId }));
                this.setState({features});
                console.log(query);
                console.log(features);
            }

          });
    }

    deleteSubs(){
        alert(this.state.subs)
        this.state.subs.forEach(this.deleteSub);
        this.props.parentReload();
    }
    addFeatureToSubs(){

        this.state.subs.map(entry =>
            this.state.selectedFeatures.map(featureEntry=>
                this.addFeatureToSub(entry,featureEntry)
            )
        )
        this.props.parentReload();

    }
    addFeatureToSub(subname,featurename){
        const addFeature = {
			featureId: featurename
		};
        axios.post(this.props.url+'subscriptions/'+subname+'/features', addFeature, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
			.then(res => {
				console.log(res);
				console.log(12);
				console.log(res.data);
				this.setState({success:true});

			  })
			  .catch(this.setState({success:false}));
	}


    dropChange(event, data){
        const selectedFeatures = data.value;
        this.setState({selectedFeatures});
    }

    handleInputChange(event) {
        const target = event.target;
        //this is all about adding checked items into a list to then later evaluate
        var a = this.state.subs.indexOf(target.value)
        if (a == -1 && !target.checked){
            //alert("didn't find it")
        }
        else if (!target.checked){
            //("removing")
            this.setState(state=>{
                const subs = state.subs.filter((item,j) => a !== j);
                return {
                    subs,
                };
            });
        }
        if (a == -1 && target.checked){
            //alert("adding it")
            this.setState(state=>{
                const subs = state.subs.concat(target.value)
                return {
                    subs,

                };
            });
        }
      }

    render(){
        var featureData = []
        var totalSystems = 0
        var totalCost = 0
        var jsxUsedFeatures = []
        for (var entry in this.state.usedFeatures){
            totalCost = totalCost + this.state.usedFeatures[entry][1]
            jsxUsedFeatures.push(<Segment>number of subscriptions using {entry} at ${this.state.usedFeatures[entry][1]}/subscription: {this.state.usedFeatures[entry][0]}</Segment>)
        }
       for (var entry in this.state.usedFeatures){
            featureData.push({label:entry,y:(this.state.usedFeatures[entry][1]*this.state.usedFeatures[entry][0])})
        }
        totalCost = totalCost.toFixed(2)
        const options = {
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: "Feature Billing"
            },
            data: [{
                type: "pie",
                startAngle: 75,
                toolTipContent: "<b>{label}</b>: ${y}/month",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 16,
                indexLabel: "{label} - ${y}/month",
                //indexLabel: "{label} - {y}%",
                /*dataPoints: [
                    { y: 18, label: "Direct" },
                    { y: 49, label: "Organic Search" },
                    { y: 9, label: "Paid Search" },
                    { y: 5, label: "Referral" },
                    { y: 19, label: "Social" }
                ]
                */
               dataPoints: featureData
            }]
        }
        return (

            <Grid stackable columns={2}>
                <Grid.Column>
                    <Segment>
                        <CanvasJSChart options = {options} />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment>
                        Total billing is  ${totalCost}/month
                    </Segment>
                    {jsxUsedFeatures}
                </Grid.Column>
            </Grid>

        )
    }
}
