import React, { Component } from 'react';
import axios from "axios";
import { Button,List, Segment } from 'semantic-ui-react'

export default class GetSubscriptions extends Component{

    constructor(props){
        super(props)
        this.state = {
            query: []
        }
        //loads systems for the user
        //this.GetSearch = this.GetSearch.bind(this);
    }

    

    componentDidMount() {
        axios.get(this.props.url+'users/'+this.props.user+'/subscriptions', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
            .then(res => {
            console.log(res);
            const query = res.data;
            console.log(query);
            this.setState({query});
          });
    }

    render(){
        return (
        <div className="comptext">
            <h3>subscriptions for user {this.props.user} </h3>
            
            {this.state.query.subscriptionId}
            <Segment inverted>
                <List divided inverted relaxed>
                    {
                    //this.state.query.map(entry => <li> {"system: " + entry.system}{" subscription Id: " + entry.subscriptionId} {entry.features.map(line => <li>{line.featureId}</li>)}</li>)
                    }
                    {
                    this.state.query.map(entry => 
                    <List.Item>
                        <List.Content>
                            <List.Header>{"system: " + entry.system}</List.Header>
                            {" subscription Id: " + entry.subscriptionId} {entry.features.map(line => <li>{line.featureId}</li>)}
                        </List.Content>
                    </List.Item>)
                    }
                        
                </List>
            </Segment>
            
        </div>
        )
    }
}