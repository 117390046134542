import React, { useState } from 'react';
import {Button} from "semantic-ui-react";

export default function LogOut() {

    const [showResults, buttonPress] = useState(false);

    function handleClick(e) {
      e.preventDefault();
      console.log('The link was clicked.');
      buttonPress(true);
      document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
     // document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.reload(false);
    }

    return (
        <div>
          <Button inverted={true} icon='sign out' content='Sign Out' labelPosition='right' floated='right' onClick={handleClick}/>
          {/*fix this logging out -> make it global loader*/}
          {showResults ? <p>logging out...</p> : null }
          {showResults}
      </div>
    );
  }
