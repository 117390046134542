import React, {Component} from 'react';
import axios from 'axios'; 

export default class AddFeatureToSubscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subscriptionId: '',
			featureId: '',
			success: false
		}
	}

	handleFeatureIdChange = event => {
		this.setState({featureId: event.target.value});
	}
	handleSubscriptionIdChange = event => {
		this.setState({subscriptionId: event.target.value});
	}
	handleSubmit = event => {
		event.preventDefault();
		const addFeature = {
			featureId: this.state.featureId
		};
		console.log(addFeature);
		axios.post(this.props.url+'subscriptions/'+this.state.subscriptionId+'/features', addFeature, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
			.then(res => {
				console.log(res);
				console.log(12);
				console.log(res.data);
				this.setState({success:true});
				
			  })
			  .catch(this.setState({success:false}));
		}

	render() {
		return(
			<div>
				<form onSubmit = {this.handleSubmit}>
					<label>
						Subscription ID:
						<input type = "text" name = "subscriptionId" onChange = {this.handleSubscriptionIdChange} />
						Feature ID:
						<input type = "text" name = "featureId" onChange = {this.handleFeatureIdChange} />
					</label>
					<button type ="submit"> Add New Feature </button>
				</form>
				{this.state.success ?//the ? is here to conditionally check request was successful
            //this.state.query.map(entry => <li>{entry.subscriptionId}</li>) //depending on data object might need map to access
            "success" : null
            }
			</div>
			)
		}
}