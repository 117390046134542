import React, { Component } from 'react';
import axios from "axios";

export default class GetSystemSubscriptions extends Component{

    constructor(props){
        super(props);

        this.state = {
            query: [],
            name: "",
            success: false
          };
          
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
      this.setState({name: event.target.value});
    }

    handleSubmit(event){
        event.preventDefault();
      
        //axios.get('http://localhost:8000/system/'+this.state.name+'/subscription')
        axios.delete(this.props.url+'users/'+this.props.user+'/subscriptions/'+this.state.name, {
          headers: {
              'Authorization': this.props.bearerToken
          }})
          .then(res => {
            console.log(res);
            const query = res.data;
            this.setState({query});
            this.setState({success:true});
            //so other components will show change
            this.props.parentReload();
          })
          .catch(this.setState({success:false}));


          //put in other axios.get functions, here, or make seperate functions for them
      }

    render(){
        
        return (
        <div>
          {//entering system name for page
          }
            <h3>Enter subscription id from above to delete it for {this.props.user}</h3>
            <form onSubmit={this.handleSubmit}>
              <label>Subscription Id: </label>
              <input type="text" value={this.state.name} onChange={this.handleChange}/>
              <input type="submit" value="Delete" />
            </form>
            {this.state.success ?//the ? is here to conditionally check request was successful
            //this.state.query.map(entry => <li>{entry.subscriptionId}</li>) //depending on data object might need map to access
            "deleted successfully" : null
            }
            {//button could go here
            }


        </div>
        )
    }
}

//put in all the delete and stuff, also put in a user sign in