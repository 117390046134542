import React, {Component} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Label, Input, Dropdown, Icon} from 'semantic-ui-react'


export default class AddNewSubscription extends Component {
	constructor(props) {
		super(props);
		this.state = {
			beginDate: '',
			expirationDate: '',
			thingName: '',
			success: false,
			features: [],
			selectedFeatures: [],
			open: false,
			open2: false,
			open3: false,
			open4: false

		}
		this.toggleModal = this.toggleModal.bind(this);
		this.dropChange = this.dropChange.bind(this);
	}

	componentDidMount() {
          axios.get(this.props.url+'features', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
          .then(res => {
            console.log(res);
            const query = res.data;
            if(Array.isArray(query)){
                const features = [];
                query.map(entry => features.push({ key: entry.featureId, text: entry.featureName + " cost: "+entry.cost, value: entry.featureId }));
                this.setState({features});
                console.log(query);
                console.log(features);
            }

          });
    }

	toggleModal(num){
		if(num==1){
			if(this.state.open == false){
				this.setState({open:true})
			}
			else if(this.state.open == true){
				this.setState({open:false})
			}
		}
		else if(num==2){
			if(this.state.open2 == false){
				this.setState({open2:true})
			}
			else if(this.state.open2 == true){
				this.setState({open2:false})
			}
		}
		else if(num==3){
			if(this.state.open3 == false){
				this.setState({open2:true})
			}
			else if(this.state.open3 == true){
				this.setState({open3:false})
			}
		}
	}
	//////////////////
	//
	// Drop down methods (redundant tbh)
	//
	/////////////////
	addFeatureToSubs(){

        this.state.subs.map(entry =>
            this.state.selectedFeatures.map(featureEntry=>
                this.addFeatureToSub(entry,featureEntry)
            )
        )
        this.props.parentReload();

    }
    addFeatureToSub(subname,featurename){
        const addFeature = {
			featureId: featurename
		};
        axios.post(this.props.url+'subscriptions/'+subname+'/features', addFeature, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
			.then(res => {
				console.log(res);
				console.log(12);
				console.log(res.data);
				this.setState({success:true});

			  })
			  .catch(this.setState({success:false}));
	}
	dropChange(event, data){
        const selectedFeatures = data.value;
        this.setState({selectedFeatures});
    }
	/////////////////////////////
    /////////////////////////////

	handleBeginDateChange = event => {
		this.setState({beginDate: event.target.value});
	}

	handleExpirationDateChange = event => {
		this.setState({expirationDate: event.target.value});
	}
	handleThingNameChange = event => {
		this.setState({thingName: event.target.value});
	}

	handleSubmit = event => {
		//close the modals when submit
		this.toggleModal(1)
		this.toggleModal(2)


		event.preventDefault();
		const addSub = {
			beginDate: this.state.beginDate,
			expirationDate: this.state.expirationDate,
			thingName: this.state.thingName
		};
		console.log(addSub);
		axios.post(this.props.url+'users/'+this.props.user+'/subscriptions', addSub, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
			.then(res => {
				console.log(res);
				console.log(12);
				console.log(res.data);
				this.setState({success:true});
				//refresh the page
				//window.location.reload(false);
			  })
			  .catch(this.setState({success:false}));
		}
	render() {
		return(
			<div>

				{/*<Button floated='right'*/}
					{/*onClick={() => this.toggleModal(1)}*/}
				{/*>*/}
					{/*Add New Subscription*/}
				{/*</Button>*/}
				<Label size='large' as='a' onClick={() => this.toggleModal(1)} color='teal' basic floating style={{left: 0, paddingTop: 6, paddingBottom: 5}}>
					<Icon name='plus'/>Add
				</Label>
				<Modal
					dimmer='blurring'
					open={this.state.open}
					onClose={() => this.toggleModal(1)}
				>
					<Modal.Header>Let's add a subscription!</Modal.Header>
					<Modal.Content>
							<Label>
								Thing Name:
								<Input type = "text" name = "thingName" onChange = {this.handleThingNameChange} />
							</Label>
							<br></br>
							{(this.state.beginDate && this.state.expirationDate) ?
							<Form onSubmit = {this.handleSubmit}>
								<Form.Button positive content ="Submit" >
									Submit with previous time range
								</Form.Button>
							</Form>
							:
							null
							}
							<Button
								onClick={() => this.toggleModal(2)}
							>
								{'Contine ->'}
							</Button>
					</Modal.Content>
					<Modal.Actions>

					<Button negative onClick={() => this.toggleModal(1)}>
						Cancel
					</Button>
					</Modal.Actions>
				</Modal>
				<Modal
					as={Form}
					dimmer='undefined'
					open={this.state.open2}
					onClose={() => {this.toggleModal(2);this.toggleModal(1);}}
				>
					<Modal.Header>Now set the date range and you're done!</Modal.Header>
					<Modal.Content>
							<Label>
								Begin Date:
								<Input type = "text" name = "beginDate" onChange = {this.handleBeginDateChange} />
								Expiration Date:
								<Input type = "text" name = "expirationDate" onChange = {this.handleExpirationDateChange} />
							</Label>
							<Dropdown placeholder='Add Features to Selected' fluid multiple selection options={this.state.features} onChange={this.dropChange}/>
							<br></br>
							<Form onSubmit = {this.handleSubmit}>
							<Form.Button positive content ="Submit" >
								Submit
							</Form.Button>
							</Form>
					</Modal.Content>
					<Modal.Actions>

					<Button negative onClick={() => {this.toggleModal(2)}}>
						Back
					</Button>

					</Modal.Actions>
				</Modal>

			</div>
			)
		}
}
