import React, { Component } from 'react';
import axios from "axios";

export default class GetSystemSubscriptions extends Component{

    constructor(props){
        super(props);

        this.state = {
            query: [],
            name: "thing-name98",
            success: true
          };
          
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
      this.setState({name: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();
      this.componentDidMount();
    }

    componentDidMount() {
        //axios.get('http://localhost:8000/system/'+this.state.name+'/subscription')
        
        axios.get(this.props.url + 'system/'+this.state.name+'/subscription', {
          headers: {
              'Authorization': this.props.bearerToken
          }})
          .then(res => {
            console.log(res);
            const query = res.data;
            this.setState({query});
            this.setState({success:true});
          })
          .catch(this.setState({success:false}));


          //put in other axios.get functions, here, or make seperate functions for them
      }

    render(){
        return (
        <div>
          {//entering system name for page
          }
            {/*<h3>Enter system name to get it's subscription</h3>*/}
            {/*<form onSubmit={this.handleSubmit}>*/}
            {/*  <label>System: </label>*/}
            {/*  <input type="text" value={this.state.name} onChange={this.handleChange}/>*/}
            {/*  <input type="submit" value="Search" />*/}
            {/*</form>*/}
            {/*{this.state.success ?//the ? is here to conditionally check request was successful*/}
            {/*//this.state.query.map(entry => <li>{entry.subscriptionId}</li>) //depending on data object might need map to access*/}
            {/*"subscription id for system: " + this.state.query.subscriptionId : "no subscriptions found for that system id"*/}
            {/*}*/}
            {/*{//button could go here*/}
            {/*}*/}
        </div>

        )
    }
}

//put in all the delete and stuff, also put in a user sign in