import React, { Component } from 'react';
import axios from "axios";
import { Button,Form,Dropdown, Segment } from 'semantic-ui-react'
import {Tab, Label, Menu, Placeholder, Grid, Header, Popup, Icon, List} from "semantic-ui-react";
import AddNewSubscription from "./AddNewSubscription";
import FeatureStore from "./FeatureStore";

export default class ManageSubscriptions extends Component{

    constructor(props){
        super(props);
        this.state = {
            query: [],
            subs: [],
            features: [],
            selectedFeatures: [],
            subscriptionsAreLoaded: false,
            activeIndex: this.props.activeTabIdx,
        };
        //loads systems for the user
        //this.GetSearch = this.GetSearch.bind(this);
        this.deleteSub = this.deleteSub.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteSubs = this.deleteSubs.bind(this);
        this.dropChange = this.dropChange.bind(this);
        this.addFeatureToSubs = this.addFeatureToSubs.bind(this);
        this.addFeatureToSub = this.addFeatureToSub.bind(this);
    }
    // TODO: this currently deletes entire subscription, do we want it just deleting features?
    deleteSub(subname){
        axios.delete(this.props.url+'users/'+this.props.user+'/subscriptions/'+subname, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
            .then(res => {
            console.log(res);
            const query = res.data;
            if(Array.isArray(query)){
                this.setState({query});
            }
            //this.setState({success:true});

            })
            .catch(this.setState({success:false}));
        }

    componentDidMount() {
        axios.get(this.props.url+'users/'+this.props.user+'/subscriptions', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
            .then(res => {
            console.log(res);
            const query = res.data;
            console.log(query);
            this.setState({query});
            this.setState({subscriptionsAreLoaded: true});
            // add first tab
            this.state.subs.push(res.data[0].subscriptionId);
                console.log(this.state);
          });

          axios.get(this.props.url+'features', {
            headers: {
                'Authorization': this.props.bearerToken
            }})
          .then(res => {
            console.log(res);
            const query = res.data;
            if(Array.isArray(query)){
                const features = [];
                query.map(entry => features.push({ key: entry.featureId, text: entry.featureName + ' ($' + entry.cost + ' /mo.)', value: entry.featureId, cost: entry.cost }));
                this.setState({features});
                console.log(query);
                console.log(features);
            }

          });
    }

    deleteSubs(){
        alert(this.state.subs);
        this.state.subs.forEach(this.deleteSub);
        this.props.parentReload(this.state.activeIndex);
    }
    addFeatureToSubs(){
        console.log(this.state.subs);
        console.log(this.state.selectedFeatures);
        this.state.subs.map(entry =>
            this.state.selectedFeatures.map(featureEntry=>
                this.addFeatureToSub(entry,featureEntry)
            )
        );

        this.props.parentReload(this.state.activeIndex);
    }
    addFeatureToSub(subname,featurename){
        console.log('adding: ' + subname);
        console.log('adding: ' + featurename);
        const addFeature = {
          featureId: featurename
        };
        axios.post(this.props.url+'subscriptions/' + subname + '/features', addFeature, {
            headers: {
                'Authorization': this.props.bearerToken
            }})
			.then(res => {
				console.log(res);
				console.log(12);
				console.log(res);
				this.setState({success:true});

			  })
			  .catch(this.setState({success:false}));
	}


    dropChange(event, data){
        const selectedFeatures = data.value;
        this.setState({selectedFeatures});
    }

    handleInputChange(event) {
        const target = event.target;
        console.log("target");
        console.log(target);
        console.log(this.state.subs);
        var a = this.state.subs.indexOf(target.value)
        console.log("a");
        console.log(a);
        if (a == -1 && !target.checked){
            // alert("didn't find it")
        }
        else if (!target.checked){
            // alert("removing")
            this.setState(state=>{
                const subs = state.subs.filter((item,j) => a !== j);
                return {
                    subs,
                };
            });
        }
        if (a == -1 && target.checked){
            // alert("adding it")
            this.setState(state=>{
                const subs = state.subs.concat(target.value)
                return {
                    subs,

                };
            });
        }
      }

    handleTabChange = (e, data) => {
        const activeSubscriptionId = data.panes[data.activeIndex].menuItem.key;
        const activeSubscriptionName = e.target.innerText.split('\n')[0];
        console.log(activeSubscriptionId);
        console.log(activeSubscriptionName);
        console.log("data on tab change");
        console.log(data.activeIndex);
        this.state.subs.pop();
        this.state.subs.push(activeSubscriptionId);
        this.setState({activeIndex: data.activeIndex});
    };



    render(){
        const panes = this.state.query.map(item => {
            return {
                menuItem: (
                    <Menu.Item key={item.subscriptionId} style={{fontSize: 15}}>
                        {/*TODO: put in flexbox*/}
                        {item.system}<Label>{item.expirationDate}</Label>
                    </Menu.Item>
                ),
                render: () =>
                    <Tab.Pane className={item.subscriptionId}>
                        <Header as='h2' dividing>
                            {item.system}
                            <Header as='h5' floated='right' style={{marginTop: 11}}>
                                {item.subscriptionId}
                            </Header>
                        </Header>
                        <Grid columns={2}>
                            <Grid.Column width={9}>
                                <Segment raised>
                                    <Label attached='top left'>System Details</Label>
                                    <List divided selection>
                                        {/*TODO: put these items in an array*/}
                                        <List.Item>
                                            <Label color='blue' horizontal>
                                                Owner:
                                            </Label>
                                            {item.owner}
                                        </List.Item>
                                        <List.Item>
                                            <Label color='teal' horizontal>
                                                Acquired:
                                            </Label>
                                            {item.beginDate}
                                        </List.Item>
                                        <List.Item>
                                            <Label color='orange' horizontal>
                                                Expires:
                                            </Label>
                                            {item.expirationDate}
                                        </List.Item>
                                        {/*TODO: fetch type and populate*/}
                                        {/*<List.Item>*/}
                                            {/*<Label color='orange' horizontal>*/}
                                                {/*Type:*/}
                                            {/*</Label>*/}
                                            {/*{item.type}*/}
                                        {/*</List.Item>*/}
                                    </List>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Segment raised>
                                    <Label attached='top left'>Features</Label>
                                    {item.features.length > 0 ?
                                        <List bulleted selection>
                                            {/*TODO: put these items in an array*/}
                                            {item.features.map(line => <List.Item
                                                key={line.featureId}>{line.featureId}</List.Item>)}
                                        </List>
                                        :
                                        <List>
                                            <List.Item>
                                                No Features
                                            </List.Item>
                                        </List>
                                    }
                                </Segment>
                            </Grid.Column>
                        </Grid>
                        <br/>
                        <br/>
                        <Dropdown placeholder='Add Features' fluid multiple selection options={this.state.features} onChange={this.dropChange}/>
                        <br/>
                        {/*TODO: add a 'total cost' estimator?*/}
                        <Button primary onClick={()=> this.addFeatureToSubs()}>Add Selected Features</Button>
                        <Button negative onClick={()=> this.deleteSubs()}>Delete Selected Features</Button>
                        <FeatureStore features={this.state.features}/>
                        <br/>
                        <br/>

                        <div key = {item.system}>
                            {/*TODO: add this back somewhere (wizard?) for bulk-add operations*/}
                            {/*<Form.Field label ={" subscription Id: " + item.subscriptionId} value ={item.subscriptionId} control='input' type='checkbox' onChange = {this.handleInputChange}/>*/}
                        </div>

                    </Tab.Pane>
            };
        });

        panes.unshift({menuItem: (
                <Menu.Item id='tabHeader' inverted disabled key='header' style={{fontSize: 17, paddingTop: 20, paddingBottom: 20}} class='disabled'>
                    {/*TODO: put in flexbox*/}
                    Property Name<Label>Expiration Date</Label>
                    <AddNewSubscription user = {this.state.userid} url={this.state.url} bearerToken={this.state.bearerToken}/>
                </Menu.Item>
            ),
        render: () =>
            <Tab.Pane>
                <Segment placeholder>
                    <Header icon>
                        <Grid>
                            <Grid.Column width={4}>
                                <Icon name='' inline/>
                            </Grid.Column>
                            <Grid.Column width={12}>

                            </Grid.Column>
                        </Grid>
                        <Header as='h2'>
                            <Icon name='hand point left outline' />
                            <Header.Content>
                                Select a property to view its details
                                <Header.Subheader>You can add a new property by clicking 'add' in the upper left corner.</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Header>
                </Segment>
            </Tab.Pane>
        });

        // panes.sort(function(a, b) {
        //     var textA = a.menuItem.key.toUpperCase();
        //     var textB = b.menuItem.key.toUpperCase();
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });
        // TODO: sorting buttons and headers for sidebar

        return (
            <div className="comptext">
            {this.state.subscriptionsAreLoaded ?
                <Tab activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} grid={{paneWidth: 11, tabWidth: 5}} menu={{ fluid: true, vertical: true }} panes={panes}/>
                :
                <Grid columns={2}>
                    <Grid.Column width={5}>
                        <Placeholder>
                            {/*TODO: THIS SHOULD CALCULATE BASED ON HOW MANY WE"RE LOADING?*/}
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Header>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                        </Placeholder>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Placeholder fluid>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Grid.Column>
                </Grid>
            }
            </div>
        )
    }
}
